<template>
  <div class="text-h1 text-center text-secondary q-pa-xl">
    Rules And Policy
  </div>
  <div class="q-pa-md rules">
    Red Skies takes pride in providing clear and understandable rules for the
    community to follow. These rules are as specific as possible to avoid
    confusion or interpretation but no system is perfect. These rules will grow
    and evolve over time with the community and gameplay requirements. As a rule
    of thumb if you think there might be a rule against something, dont do it.
    <div class="text-h3 text-center q-pt-xl q-pb-md">Community Rules</div>
    <q-list style="max-width: 800px">
      <q-item>
        <q-item-section>
          <q-item-label class="text-accent">No Discrimination</q-item-label>
          <q-item-label caption
            >Community members and staff shall not discriminate on the basis of
            race, color, religion (creed), gender, gender expression, age,
            national origin (ancestry), disability, marital status, sexual
            orientation, or military status
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-item-label class="text-accent">No Cheating</q-item-label>
          <q-item-label caption
            >Play fairly and within the rules of the game. Don’t cheat, grief,
            team, or exploit bugs and glitches. Don’t promote or advertise known
            cheats, bugs, or exploits. Be sure to report any bugs or exploits
            you might come across, failure to do so willingly will result in
            severe disciplenary actions.
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>

    <div class="text-h3 text-center q-pt-xl q-pb-md">Roleplay Rules</div>
  </div>
</template>
<style scoped lang="sass">
.rules
  width: 800px
  margin: 0 auto

.text-h3
  font-family: Headline !important
</style>
