import { render } from "./Rules.vue?vue&type=template&id=60d5bbc8&scoped=true"
const script = {}

import "./Rules.vue?vue&type=style&index=0&id=60d5bbc8&scoped=true&lang=sass"
script.render = render
script.__scopeId = "data-v-60d5bbc8"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QItemLabel});
